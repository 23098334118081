/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.formLogo {
  max-width: 200px;
}

.grecaptcha-badge { opacity:0; visibility:hidden; }

.daypicker-wrap input {
  padding: 5px;
  text-align: center;
  @apply bg-gray-100;
  @apply rounded-md;
  @apply border-2;
  @apply text-gray-700;
  @apply cursor-pointer;
}

.LogoBlockContainer {
  max-width: 448px;
  /* margin-left: auto;
  margin-right: auto; */
}

.gseoLabelWrap a,
.gseoSubLabelWrap a {
  color: rgb(37, 99, 235);
}

@media (min-width: 1100px) {
  .Layout2PageLeft {
    max-width: 560px;
  }
  .Layout2PageRight {
    max-width: 1100px;
  }
}

@media (min-width: 1024px) {
  .LogoBlockContainer {
    width: 500px;
  }
}
